* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --colorFontPrimary: #1ed488;
  --colorFontSecondary: #d8461f;
  --colorFontThird: #2f8d66;

  --colorWhite: rgba(255, 255, 255, 0.752);
  --colorGrey:  #444;
  --colorLightGrey: #ebebeb;
  --colorGreenTransparent: #1ed488de;
  --colorError: #d81f1f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
